<template>
  <div>
    <CCard v-if="isResetRequested === false">
      <CCardBody>
        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>

        <CRow style="margin-bottom: 1rem" v-if="!inventoryFilter && !itemFilter && shouldBeListVisible == true">
          <CCol>
            <CButton color="secondary" @click="goBack()" class="btn-lg" style="margin-right: 15px">
              <CIcon name="cilChevronLeft" />
              Back to inventories
            </CButton>
          </CCol>
        </CRow>
        <CRow v-if="shouldBeListVisible" style="margin-bottom: 1rem">
          <CCol sm="1">
            <CInputRadio @change="calendarView = false" label="List View" type="radio" name="types"
              :checked.sync="listView" />
          </CCol>
          <CCol sm="1">
            <CInputRadio @change="listView = false" label="Calendar View" type="radio" name="types"
              :checked.sync="calendarView" />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="2" v-if="experienceFilter">
            <CSelect label="Experience" :value.sync="experienceId" :plain="true" :options="experiences"
              placeholder="Select an experience">
            </CSelect>
          </CCol>
          <CCol sm="2" v-if="paymentStatusFilter">
            <label>State</label>
            <CMultiSelect :multiple="true" :selection="true" v-model="paymentStatusValues" @update="appendValue($event)"
              optionsEmptyPlaceholder="No options placeholder" selectionType="text" :options="options"
              :selected="paymentStatusValues" />
          </CCol>
          <CCol v-if="inventoryFilter" sm="2">
            <CSelect label="Inventory" :value.sync="inventoryId" :plain="true" :options="inventories"
              @change="sortItems()">
            </CSelect>
          </CCol>
          <CCol v-if="itemFilter" sm="2">
            <CSelect label="Inventory items" :value.sync="inventoryItemId" :plain="true" :options="inventoryItemsList"
              @change="filterByInventoryItem()">
            </CSelect>
          </CCol>
          <CCol sm="0">
            <CButton color="info" @click="resetFilters()" class="btn" style="margin-top: 28px">
              <CIcon name="cilXCircle" />
              Reset filters
            </CButton>
          </CCol>
        </CRow>



        <ejs-schedule v-if="inventoriesLoaded && inventoryItemsListLoaded" id='Schedule' ref="reftest" height="70vh"
          width="100%" :allowDragAndDrop='false' :quickInfoTemplates="quickInfoTemplates" :enableAdaptiveUI="false"
          :selectedDate="selectedDate" :eventRendered="onEventRendered" :popupOpen="popupOpen" :popupClose="popupClose"
          :eventSettings="eventSettings" :editorTemplate="'editorTemplate'" :quickInfoOnSelectionEnd="true"
          :group='group' @cellClick="onCellClick" @eventClick="onCellClick" :actionBegin="onActionBegin"
          currentView="TimelineMonth">
          <e-resources>
            <e-resource field="RelatedBookingId" title="RelatedBooking" name="RelatedBookings"
              :dataSource="relatedBookings" textField="bookingNumber" idField="id" colorField="color">
            </e-resource>
            <e-resource field="Availability" title="Availability" name="Availabilities" :dataSource="availabilities"
              textField="text" idField="id" colorField="color">
            </e-resource>
            <e-resource field="InventoryId" title="Inventory" name="Inventories" :dataSource="calendarInventories"
              textField="label" idField="value" colorField="color">
            </e-resource>
            <e-resource field="InventoryItemId" title="InventoryItem" name="InventoryItems" :allowMultiple="true"
              :dataSource="calendarInventoryItemsList" textField="label" idField="value" groupIDField='inventoryId'
              colorField="color">
            </e-resource>
          </e-resources>
          <template v-slot:footerTemplate="{ data }">
            <div class="quick-info-footer">
              <ejs-button v-if="data.BookingId && data.BookingId != emptyGuid" style="color: #e3165b" id="more-details"
                cssClass="e-flat" content="Open booking" v-on:click.native="moreDetails(data)"></ejs-button>
            </div>
          </template>
          <e-views>
            <e-view option="TimelineDay"></e-view>
            <e-view option="TimelineWeek"></e-view>
            <e-view option="TimelineWorkWeek"></e-view>
            <e-view option="TimelineMonth"></e-view>
            <e-view option="Agenda"></e-view>
          </e-views>

          <template v-slot:editorTemplate="{ data }">
            <table class="schedule-custom-event-editor" width="100%" cellpadding="5">
              <tbody>
                <tr>
                  <td class="e-textlabel">Subject</td>
                  <td colspan="4">
                    <input id="Subject" class="e-field e-input" type="text" value="" name="Subject" required
                      style="width: 100%" />
                  </td>
                </tr>
                <tr v-show="!isAllDayValue">
                  <td class="e-textlabel">From</td>
                  <td colspan="4">
                    <ejs-datetimepicker :value="startTime" @change="onStartTimeChange" required id="StartTime"
                      name="StartTime"></ejs-datetimepicker>

                  </td>
                </tr>
                <tr v-show="isAllDayValue">
                  <td class="e-textlabel">From</td>
                  <td colspan="4">
                    <ejs-datetimepicker :value="startTime" @change="onStartTimeChange" required id="StartTime"
                      name="StartTime"></ejs-datetimepicker>

                  </td>
                </tr>

                <tr v-show="!isAllDayValue">
                  <td class="e-textlabel">To</td>
                  <td colspan="4">
                    <ejs-datetimepicker :value="endTime" @change="onEndTimeChange" required id="EndTime"
                      name="EndTime"></ejs-datetimepicker>

                  </td>
                </tr>
                <tr v-show="isAllDayValue">
                  <td class="e-textlabel">To</td>
                  <td colspan="4">
                    <ejs-datetimepicker :value="endTime" @change="onEndTimeChange" required id="EndTime"
                      name="EndTime"></ejs-datetimepicker>

                  </td>
                </tr>
                <tr>
                  <td class="e-textlabel">All day</td>
                  <td colspan="4">
                    <ejs-checkbox class="e-field" v-model="isAllDayValue" :checked="isAllDayValue"
                      @change="changeAllDay(data)"></ejs-checkbox>
                  </td>
                </tr>
                <tr>
                  <td class="e-textlabel">Inventory</td>
                  <td colspan="4">
                    <ejs-dropdownlist required id='Inventories' name="Inventories" data-name="InventoryId"
                      class="e-field" placeholder='Choose inventory' @change="filterInventoryItems"
                      :dataSource='calendarInventories' :fields="{ text: 'label', value: 'value' }">
                    </ejs-dropdownlist>
                  </td>
                </tr>
                <tr>
                  <td class="e-textlabel">Inventory item</td>
                  <td colspan="4">
                    <ejs-multiselect required ref="inventoryItemsMultiselect" v-model="selectedInventoryItems"
                      @change="args => onInventoryItemChange(args, data)" id='InventoryItems' name="InventoryItems"
                      data-name="InventoryItemId" class="e-field" placeholder='Choose inventory item'
                      :dataSource='popupInventoryItemsList' mode="CheckBox" :showSelectAll="true"
                      selectAllText="Select All" unSelectAllText="Deselect All"
                      :fields="{ text: 'label', value: 'value' }">
                    </ejs-multiselect>
                  </td>
                </tr>
                <tr>
                  <td class="e-textlabel">Related booking</td>
                  <td colspan="4">
                    <ejs-dropdownlist id='RelatedBookings' name="RelatedBookings" allow-filtering="true"
                      data-name="RelatedBookingId" class="e-field" placeholder='Choose related booking'
                      :dataSource='relatedBookings' :filtering='filterBookings'
                      :fields="{ text: 'bookingNumber', value: 'id' }">
                    </ejs-dropdownlist>
                  </td>
                </tr>
                <tr>
                  <td class="e-textlabel">Availability</td>
                  <td colspan="4">
                    <ejs-dropdownlist required id='Availabilities' name="Availabilities" data-name="Availability"
                      class="e-field" placeholder='Choose status' :dataSource='availabilities'
                      :fields="{ text: 'text', value: 'id' }">
                    </ejs-dropdownlist>
                  </td>
                </tr>
                <tr>
                  <td class="e-textlabel">Add a note</td>
                  <td colspan="4">
                    <textarea id="Description" class="e-field e-input" name="Description" rows="3" cols="50"
                      style="width: 100%; height: 60px !important; resize: vertical"></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </ejs-schedule>
      </CCardBody>
    </CCard>
    <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="isResetRequested === true"
      color="primary" />
    <BookingModal v-if="showBookingModal" :bookingId.sync="selectedBookingId" :bookingTitle.sync="selectedBookingTitle"
      :showBookingModalProp.sync="showBookingModal"></BookingModal>
  </div>
</template>

<script>
import axios from "axios";
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-vue-schedule/styles/material.css";
import "@syncfusion/ej2-vue-dropdowns/styles/material.css";
import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
import { Query } from "@syncfusion/ej2-data";
import Vue from "vue";
import { extend } from "@syncfusion/ej2-base";
import { WorkWeek, Month, Week, ScheduleComponent, ViewDirective, ViewsDirective, ResourceDirective, ResourcesDirective, Agenda, TimelineViews, TimelineMonth, Resize, DragAndDrop } from "@syncfusion/ej2-vue-schedule";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DateTimePicker } from "@syncfusion/ej2-calendars";
import { CheckBoxComponent } from "@syncfusion/ej2-vue-buttons";
import { DateTimePickerComponent, DatePickerComponent } from '@syncfusion/ej2-vue-calendars';
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection } from '@syncfusion/ej2-vue-dropdowns';
import BookingModal from "@/components/BookingModal.vue"
import { nextTick } from 'vue';
export default {
  props: {
    items: [],
    inventoryFilter: {
      type: Boolean,
      default: true,
    },
    itemFilter: {
      type: Boolean,
      default: true,
    },
    experienceFilter: Boolean,
    paymentStatusFilter: Boolean,
    shouldBeListVisible: Boolean,
  },
  name: "CalendarView",
  components: {
    FullCalendar,
    BookingModal,
    'ejs-schedule': ScheduleComponent,
    'e-view': ViewDirective,
    'e-views': ViewsDirective,
    'e-resource': ResourceDirective,
    'e-resources': ResourcesDirective,
    'ejs-datetimepicker': DateTimePickerComponent,
    'ejs-dropdownlist': DropDownListComponent,
    'ejs-multiselect': MultiSelectComponent,
    'ejs-button': ButtonComponent,
    'ejs-checkbox': CheckBoxComponent,
    'ejs-datepicker': DatePickerComponent
  },
  watch: {
    inventoryItemsList: function (val) {

    },
  },
  data: function () {
    return {
      selectedDate: new Date(),
      formData: {
        Title: "",
        Description: "",
        StartTime: "",
        EndTime: "",
      },
      action: "", // 'Add' or 'Edit' based on the action
      paymentStatusValues: [2, 5, 6],
      calendarItemSubject: null,
      calendarItemNote: null,
      relatedBookings: [],
      isBusy: true,
      selectedBookingId: null,
      selectedBookingTitle: null,
      showBookingModal: false,
      calendarItem: {
        inventoryItemId: null,
        startTime: null,
        endTime: null,
        isBlocked: false,
        subject: null,
        customerId: null,
        notes: null,
      },
      isEditPopupOpen: false,
      selectedInventoryItems: null,
      isAllDayValue: false,
      quickInfoTemplates: {
        footer: "footerTemplate"
      },
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      selectedDate: new Date(),
      relatedBookingId: "00000000-0000-0000-0000-000000000000",
      isAvailable: false,
      option: null,
      options: [
        {
          value: 1,
          text: "Unpaid",
        },
        {
          value: 2,
          text: "Paid",
        },
        {
          value: 3,
          text: "Canceled",
        },
        {
          value: 4,
          text: "Expired",
        },
        {
          value: 5,
          text: "Confirmed",
        },
        {
          value: 6,
          text: "PartialPaid",
        },
      ],
      bookings: [],
      listView: true,
      warningModal: false,
      calendarView: false,
      shouldBeBlocked: false,
      deleteBooking: null,
      experienceId: "00000000-0000-0000-0000-000000000000",
      selectedTimeSlotId: "00000000-0000-0000-0000-000000000000",
      paymentStatus: "",
      inventoryId: "00000000-0000-0000-0000-000000000000",
      inventoryItemId: "00000000-0000-0000-0000-000000000000",
      experiences: [],
      paymentStatuses: [],
      internalItems: [],
      inventories: [],
      calendarInventories: [],
      shouldBeUnblocked: false,
      intermediateAvailabiltyResponse: [],
      lastOpenedEventData: [],
      isResetRequested: false,
      visiblePopUp: false,
      calendarItemToBeDeleted: "00000000-0000-0000-0000-000000000000",
      popupTitle: "",
      startTime: null,
      endTime: null,
      popupContent: "",
      start: null,
      end: null,
      inventoryItemsList: [],
      calendarInventoryItemsList: [],
      popupInventoryItemsList: [],
      allInventoryItems: [],
      inventoriesLoaded: false,
      inventoryItemsListLoaded: false,
      alertType: "danger",
      message: null,
      blocked: {
        id: "",
        backgroundColor: "",
        guestNames: "",
        isBooking: false,
        isPaid: false,
        start: "",
        title: "Unavailable",
      },
      //Alert
      alertType: "danger",
      message: null,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        events: [],
        eventDidMount: function (eventInfo) {
          eventInfo.el.onmouseenter = function () {
            eventInfo.el.title = eventInfo.event.extendedProps.fullDescription;
          };
        },
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        eventClick: this.handleClick,
        select: this.handleDateSelect,
        weekends: true,
        timeZone: "UTC",
        initialDate: new Date(),
      },
      eventSettings: {
        dataSource: [],
      },
      group: {
        allowGroupEdit: true,
        enableCompactView: false,
        resources: ['Inventories', 'InventoryItems']
      },
      ownerDataSource: [
        { text: "Nancy", id: 1, color: "#1aaa55" },
        { text: "Smith", id: 2, color: "#7fa900" },
        { text: "Paul", id: 3, color: "#357cd2" },
      ],
      availabilities: [
        { text: "Is Blocked", id: 1, color: "#d71f1f" },
        { text: "Is Available", id: 2, color: "#58c318" },
      ],
      editorTemplate: function (e) {
        return {
          template: editorTemplateVue,
        };
      },
    };
  },
  provide: {
    schedule: [WorkWeek, Month, Week, Agenda, TimelineViews, TimelineMonth, Resize, DragAndDrop],
    multiselect: [CheckBoxSelection]
  },
  computed: {},
  methods: {
    onInventoryItemChange(args, data) {
      if (!args.isInteracted && args.value && args.value.length == 0 && args.oldValue && args.oldValue.length > 0)
        return;
      //data.InventoryItemId = args.value;
      this.lastOpenedEventData = args.value;
    },
    changeAllDay(data) {
      data.IsAllDay = !data.IsAllDay;
      if (data.IsAllDay) {
        data.StartTime.setHours(0, 0, 0);
        data.EndTime.setHours(0, 0, 0);
      }
    },
    filterInventoryItems(data) {
      if (data.value == this.emptyGuid) {
        this.popupInventoryItemsList = this.allInventoryItems;
      }
      else {
        this.popupInventoryItemsList = this.allInventoryItems.filter(inventoryItem => inventoryItem.inventoryId == data.value);
      }

      try {
        if (data.isInteracted)
          this.$refs.inventoryItemsMultiselect.clear();
      }
      catch {

      }
    },
    moreDetails(data) {
      this.showBookingModal = false;
      this.$nextTick(() => {
        this.selectedBookingId = data.BookingId;
        this.selectedBookingTitle = data.Subject;
        this.showBookingModal = true;
      });
    },
    onEventRendered: function (args) {
      if (args.data.Availability == 1) {
        args.element.style.backgroundColor = '#c13434';
      }
      else if (args.data.Availability == 2) {
        args.element.style.backgroundColor = '#58c318';
      }

    },
    popupOpen: function (args) {
      if (args.type === "Editor") {
        this.isAllDayValue = args.data.IsAllDay;
        this.selectedInventoryItems = args.data.InventoryItemId;
        this.isEditPopupOpen = true;
        this.startTime = new Date(args.data.StartTime); // Ensure it's a Date object
        this.endTime = new Date(args.data.EndTime); // Ensure it's a Date object
      }
      let isEmptyCell = args.target &&
        (args.target.classList.contains("e-work-cells") ||
          args.target.classList.contains("e-header-cells")); // checking whether the cell is empty or not
      if (
        (args.type === "QuickInfo") &&
        isEmptyCell
      ) {
        args.cancel = true;
      }
    },
    popupClose(args) {
      if (args.type === "Editor" && !args.data) {
        this.isEditPopupOpen = false;
      }
    },
    appendValue(event) {
      this.paymentStatusValues = event;
    },
    handleDateSelect(selectInfo) {
      let startDisplay;
      let endDisplay;
      if (this.inventoryItemId != "00000000-0000-0000-0000-000000000000") {
        this.start = new Date();
        this.end = new Date();
        let title = this.inventoryItemsList.find(
          (x) => x.value == this.inventoryItemId
        ).label;
        let intermediateEndDate = new Date(selectInfo.endStr);
        intermediateEndDate.setDate(intermediateEndDate.getDate() - 1);
        intermediateEndDate = intermediateEndDate.toISOString();
        if (
          selectInfo.startStr.includes("T") &&
          selectInfo.endStr.includes("T")
        ) {
          startDisplay = new Date(selectInfo.startStr).toJSON();
          endDisplay = new Date(selectInfo.endStr).toJSON();
        } else {
          startDisplay = selectInfo.startStr;
          endDisplay = selectInfo.endStr;
          var elements = intermediateEndDate.split("T");
          intermediateEndDate = elements.at(0);
          endDisplay = intermediateEndDate;
        }
        this.start = selectInfo.startStr;
        this.end = selectInfo.endStr;
        this.popupContent =
          "Are you sure you want to block availability for " +
          title +
          " between specified interval?";
        this.popupTitle =
          "Calendar item management between " +
          startDisplay +
          " and " +
          endDisplay;
        this.visiblePopUp = true;
      }
    },
    onStartTimeChange(event) {
      this.startTime = event.value;
    },
    onEndTimeChange(event) {
      this.endTime = event.value;
    },
    closeModal(status, evt, accept) {
      console.log("hello vlad");
      console.log(this.startTime);
      let self = this;
      if (accept) {
        let self = this;
        if (self.shouldBeUnblocked === true) {
          axios
            .get(
              `${this.$apiAdress}/v1/Calendar/UnblockAvailability?calendarItemId=${self.calendarItemToBeDeleted}`
            )
            .then(function (response) {
              self.calendarItemToBeDeleted =
                "00000000-0000-0000-0000-000000000000";
              self.getAvailabilities();
            })
            .catch(function (error) {
              self.alertType = "danger";
              setTimeout(function () {
                self.message = null;
              }, 10000);
              self.message = error.response.data.message;
            });
        } else {
          self.calendarItem.inventoryItemId = self.inventoryItemId;
          self.calendarItem.startTime = self.start;
          self.calendarItem.endTime = self.end;
          self.calendarItem.notes = self.calendarItemNote;
          self.calendarItem.subject = self.calendarItemSubject;
          self.calendarItem.isBlocked = self.isBusy;
          debugger;
          axios
            .post(
              `${this.$apiAdress}/v1/Calendar/BlockAvailability/${self.relatedBookingId}`,
              self.calendarItem
            )
            .then(function (response) {
              self.getAvailabilities();
              self.visiblePopUp = false;
            })
            .catch(function (error) {
              self.alertType = "danger";
              setTimeout(function () {
                self.message = null;
              }, 10000);
              self.message = error.response.data.message;
            });
        }
      } else {
        self.visiblePopUp = false;
      }
      self.shouldBeUnblocked = false;
    },
    handleClick(eventInfo) {
      let self = this;
      let title = this.inventoryItemsList.find(
        (x) => x.value == this.inventoryItemId
      ).label;
      this.popupContent =
        "Are you sure you want to unblock item " +
        title +
        " between specified interval?";
      if (eventInfo.event.endStr == "") {
        this.popupTitle =
          "Unblocking availability for the day " +
          eventInfo.event.startStr +
          ".";
      } else {
        var elements1 = eventInfo.event.startStr.split("T");
        var elements2 = eventInfo.event.endStr.split("T");
        this.popupTitle =
          "Unblocking availability between " +
          elements1.at(0) +
          " and " +
          elements2.at(0);
      }
      this.shouldBeUnblocked = true;
      this.calendarItemToBeDeleted = eventInfo.event.id;
      this.visiblePopUp = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    getList() {
      this.isResetRequested = true;
      this.calendarViewEvents = [];
      this.calendarOptions.events = [];
      this.populateCalendarEvents();
    },
    getAllRelatedBookings() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Booking/GetAll")
        .then(function (response) {
          self.relatedBookings = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    resetFilters() {
      let self = this;
      self.experienceId = "00000000-0000-0000-0000-000000000000";
      if (!!this.$route.query.inventoryItemId) {
        self.inventoryItemId = this.$route.query.inventoryItemId;
      } else {
        self.inventoryItemId = "00000000-0000-0000-0000-000000000000";
      }
      if (!!this.$route.query.inventoryId) {
        self.inventoryId = this.$route.query.inventoryId;
      } else {
        self.inventoryId = "00000000-0000-0000-0000-000000000000";
      }
      self.paymentStatusValues = [2, 5, 6];
      //this.getList();
      self.getFilteredDataSource(self.eventSettings.dataSource);
      this.populateFiltersDataSource("GetAllInventoryItemsWithParent");
    },
    populateCalendarEvents() {
      let self = this;
      axios
        .get(
          this.$apiAdress +
          "/v1/Calendar/" +
          "GetCalendarEvents?experienceId=" +
          self.experienceId +
          "&paymentStatus=" +
          self.paymentStatuses +
          "&inventoryId=" +
          self.inventoryId +
          "&inventoryItemId=" +
          self.inventoryItemId
        )
        .then(function (response) {
          self.calendarViewEvents = response.data;
          let dataSource = [];
          self.calendarViewEvents.map(function (value, key) {
            value.id = value.id;
            value.title = value.title == null ? "Unavailable" : value.title;
            value.start = value.start;
            value.end = value.end;
            value.guestNames = value.guestNames;
            value.bookingNo = value.bookingNo;
            value.fullDescription = value.fullDescription;
            if (value.isPaid === true) {
              value.backgroundColor = "#00FF00";
            } else {
              value.backgroundColor = "#808080";
            }

            self.calendarOptions.events.push(value);
            dataSource.push({
              Id: value.id,
              Subject: value.subject == null ? "Unavailable" : value.subject,
              StartTime: new Date(value.startTime),
              EndTime: new Date(value.endTime),
              RelatedBookingId: value.bookingNo,
              Availability: value.isBlocked ? 1 : 2,
              InventoryId: value.inventoryId,
              InventoryItemId: value.inventoryItemIds,
              Description: value.notes,
              IsPaid: value.isPaid,
              IsAllDay: self.isAllDay(value.startTime, value.endTime),
              BookingId: value.bookingId
            });
          });
          self.eventSettings.dataSource = extend([], dataSource, null, true)

          self.isResetRequested = false;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
      axios
        .get(this.$apiAdress + "/v1/Partner/gettimezoneofpartner")
        .then(function (response) {
          self.calendarOptions.timeZone = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    isAllDay(startTime, endTime) {
      var start = new Date(startTime);
      var end = new Date(endTime);
      if (start.getHours() == 0 && start.getMinutes() == 0 && end.getHours() == 0 && end.getMinutes() == 0)
        return true;
      else
        return false;
    },
    filterBookings(filterEvent) {
      const searchText = filterEvent.text.toLowerCase();
      let filteredBookings = [];
      if (searchText == "") {
        filteredBookings = this.relatedBookings;
      }
      else {
        filteredBookings = this.relatedBookings.filter(booking => booking.bookingNumber?.toLowerCase().includes(searchText) ||
          booking.customer?.firstName?.toLowerCase().includes(searchText) ||
          booking.customer?.lastName?.toLowerCase().includes(searchText) ||
          booking.guestNames?.toLowerCase().includes(searchText));
      }

      filterEvent.updateData(filteredBookings);
    },
    filterByInventoryItem() {
      this.getFilteredDataSource(this.eventSettings.dataSource);
    },
    populateFiltersDataSource(controllerName) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/" + controllerName)
        .then(function (response) {
          switch (controllerName) {
            case "InventoryList":
              self.inventories = response.data;
              self.inventories.unshift({ label: "All inventories", value: self.emptyGuid });
              self.inventoryId = self.emptyGuid;
              self.calendarInventories = response.data;
              self.inventoriesLoaded = true;
              break;
            case "ExperienceList":
              self.experiences = response.data;
              break;
            case "GetAllInventoryItemsWithParent":
              self.allInventoryItems = response.data;
              self.inventoryItemsList = [{ label: "All inventory items", value: self.emptyGuid }, ...new Map(response.data.map(item => [item['label'], item])).values()];
              self.calendarInventoryItemsList = response.data.filter(inventoryItem => inventoryItem.value != "00000000-0000-0000-0000-000000000000");
              self.popupInventoryItemsList = response.data.filter(inventoryItem => inventoryItem.value != "00000000-0000-0000-0000-000000000000");
              self.inventoryItemsListLoaded = true;
              break;
          }
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    sortItems(event) {
      if (this.inventoryId == this.emptyGuid) {
        this.resetFilters();
        return;
      }
      let self = this;
      self.eventSettings.dataSource = [];
      axios
        .get(
          this.$apiAdress +
          "/v1/ListItem/InventoryItemsFiltered/" +
          self.inventoryId
        )
        .then(function (response) {
          self.inventoryItemsList = response.data;
          self.getFilteredDataSource(self.eventSettings.dataSource);
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    changeTimezoneToUtc(date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(),
        date.getDate(), date.getHours(),
        date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    },
    toUTCDate(localDate) {
      return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), localDate.getHours(), localDate.getMinutes(), localDate.getSeconds()));
    },
    onActionBegin(args) {
      let self = this;
      console.log("hei vlad");
      console.log(this.startTime);
      console.log("args are");
      console.log(args);
      if (args.requestType == "eventCreate") {
        console.log("hei ");
        args.data.forEach(data => {
          let utcStartTime = this.toUTCDate(this.startTime);
          let utcEndTime = this.toUTCDate(this.endTime);
          if (utcStartTime.getUTCDate() != utcEndTime.getUTCDate()) {
            utcEndTime.setUTCDate(utcEndTime.getUTCDate() + 1);
          }
          console.log("start time is");
          console.log(this.startTime);
          console.log("end time is");
          console.log(this.endTime);

          if (self.isAllDayValue)
            data.IsAllDay = true;
          data.InventoryItemId = self.lastOpenedEventData;
          self.isEditPopupOpen = false;
          self.calendarItem.inventoryItemId = self.inventoryItemId;
          self.calendarItem.startTime = self.start;
          self.calendarItem.endTime = self.end;
          self.calendarItem.notes = self.calendarItemNote;
          self.calendarItem.subject = self.calendarItemSubject;
          self.calendarItem.isBlocked = self.isBusy;
          axios
            .post(
              `${this.$apiAdress}/v1/Calendar/BlockAvailability/${data.RelatedBookingId ?? '00000000-0000-0000-0000-000000000000'}`,
              {
                inventoryId: data.InventoryId,
                inventoryItemsIds: data.InventoryItemId,
                startTime: utcStartTime.toISOString(), // Convert to ISO string for transmission
                endTime: utcEndTime.toISOString(),
                isBlocked: data.Availability == 1 ? true : false,
                subject: data.Subject,
                notes: data.Description
              }
            )
            .then(function (response) {
              //self.getAvailabilities();
              data.Id = response.data;
              self.visiblePopUp = false;
            })
            .catch(function (error) {
              self.alertType = "danger";
              setTimeout(function () {
                self.message = null;
              }, 10000);
              self.message = error.response.data.message;
            });
        });
      }
      else if (args.requestType == "eventChange") {
        console.log("voi 2");
        self.isResetRequested = true;

        if (args.data.StartTime.getDate() != args.data.EndTime.getDate()) {
          if (self.isEditPopupOpen && args.changedRecords && args.changedRecords.length > 0) {
            args.data.EndTime.setDate(args.data.EndTime.getDate() + 1);
            self.isEditPopupOpen = false;
          }
        }
        self.calendarItem.inventoryItemId = self.inventoryItemId;
        self.calendarItem.startTime = self.startTime;
        self.calendarItem.endTime = self.endTime;
        self.calendarItem.notes = self.calendarItemNote;
        self.calendarItem.subject = self.calendarItemSubject;
        self.calendarItem.isBlocked = self.isBusy;
        args.data.InventoryItemId = self.lastOpenedEventData;
        axios
          .post(
            `${this.$apiAdress}/v1/Calendar/BlockAvailability/${args.data.RelatedBookingId ?? '00000000-0000-0000-0000-000000000000'}`,
            {
              id: args.data.Id,
              inventoryId: args.data.InventoryId,
              inventoryItemsIds: args.data.InventoryItemId,
              startTime: self.startTime,
              endTime: self.endTime,
              isBlocked: args.data.Availability == 1 ? true : false,
              subject: args.data.Subject,
              notes: args.data.Description
            }
          )
          .then(function (response) {
            console.log("then method");
            self.getAvailabilities();
            self.visiblePopUp = false;
            // self.populateCalendarEvents();
            // self.isResetRequested = false;
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error.response.data.message;
          });
      }
      else if (args.requestType == "eventRemove") {
        axios
          .get(
            `${this.$apiAdress}/v1/Calendar/UnblockAvailability?calendarItemId=${args.data[0].Id}`
          )
          .then(function (response) {
            //self.getAvailabilities();
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error.response.data.message;
          });
      }
    },
    getFilteredDataSource(dataSource) {
      if (this.inventoryId && this.inventoryId != "00000000-0000-0000-0000-000000000000") {
        dataSource = dataSource.filter(data => data.InventoryId == this.inventoryId);
        this.calendarInventories = this.inventories.filter(inventory => inventory.value == this.inventoryId);
      }
      else {
        this.calendarInventories = this.inventories
      }
      if (this.inventoryItemId && this.inventoryItemId != "00000000-0000-0000-0000-000000000000") {
        dataSource = dataSource.filter(data => data.InventoryItemId == this.inventoryItemId);
        this.calendarInventoryItemsList = this.allInventoryItems.filter(inventoryItem => inventoryItem.value == this.inventoryItemId && inventoryItem.value != "00000000-0000-0000-0000-000000000000");
      }
      else {
        this.calendarInventoryItemsList = this.allInventoryItems.filter(inventoryItem => inventoryItem.value != "00000000-0000-0000-0000-000000000000");
      }
      return dataSource;
    },
    onCellClick(args) {
      let scheduleObj = document.getElementById('Schedule').ej2_instances[0];
    },
    getAvailabilities(event) {
      if (this.shouldBeListVisible === false) {
        this.isResetRequested = true;
        let self = this;
        self.intermediateAvailabiltyResponse = [];
        self.calendarOptions.events = [];
        axios
          .get(
            this.$apiAdress +
            "/v1/Calendar/GetAvailabilities?inventoryItemId=" +
            self.inventoryItemId +
            "&inventoryId=" +
            self.inventoryId
          )
          .then(function (response) {
            self.intermediateAvailabiltyResponse = response.data;
            let dataSource = [];
            self.intermediateAvailabiltyResponse.map(function (value, key) {
              value.id = value.id;
              value.title = value.title;
              value.start = value.start;
              value.fullDescription = value.fullDescription;
              value.guestNames = value.guestNames;
              value.bookingNo = value.bookingNo;
              if (value.isBooking === true) {
                if (value.isPaid === true) {
                  value.backgroundColor = "#00FF00";
                } else {
                  value.backgroundColor = "#808080";
                }
              } else {
                value.backgroundColor = "#ff4d4d";
              }
              self.calendarOptions.events.push(value);
              dataSource.push({
                Id: value.id,
                Subject: value.subject == null ? "Unavailable" : value.subject,
                StartTime: new Date(value.startTime),
                EndTime: new Date(value.endTime),
                RelatedBookingId: value.bookingNo,
                Availability: value.isBlocked ? 1 : 2,
                InventoryId: value.inventoryId,
                InventoryItemId: value.inventoryItemIds,
                Description: value.notes,
                IsPaid: value.isPaid,
                IsAllDay: self.isAllDay(value.startTime, value.endTime),
                BookingId: value.bookingId
              });
            });
            dataSource = self.getFilteredDataSource(dataSource);
            self.eventSettings.dataSource = extend([], dataSource, null, true);
            if (self.start != null && self.start != undefined) {
              self.calendarOptions.initialDate = new Date(self.start);
            }
            self.isResetRequested = false;
            self.visiblePopUp = false;
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            self.isResetRequested = false;
            self.visiblePopUp = false;
          });
      }
    },
  },
  mounted: function () {
    if (!!this.$route.query.inventoryItemId) {
      this.inventoryItemId = this.$route.query.inventoryItemId;
    }
    if (!!this.$route.query.inventoryId) {
      this.inventoryId = this.$route.query.inventoryId;
    }
    this.isResetRequested = true;
    this.populateCalendarEvents(); //required for populate calendar events filtered by invetoryid and inventoryitem id taken from route
    this.populateFiltersDataSource("ExperienceList");
    this.populateFiltersDataSource("PaymentStatusList");
    this.populateFiltersDataSource("InventoryList");
    this.populateFiltersDataSource("GetAllInventoryItemsWithParent");
    this.getAllRelatedBookings();
    this.internalItems = this.items;
  },
};
</script>

<style>
div.fc-event-time {
  color: black !important;
}

div.fc-event-title.fc-sticky {
  color: black !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(100, 100, 100, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.e-content-wrap::-webkit-scrollbar {
  display: inherit;
}

.e-content-wrap:hover::-webkit-scrollbar {
  width: 10px;
}

.e-content-wrap:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

.e-content-wrap:hover::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(100, 100, 100, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.e-subject.e-text-ellipsis {
  background-color: transparent !important;
}

.e-resource-text:hover {
  overflow: visible;
  white-space: normal;
  width: auto;

}

.e-popup-footer {
  display: block !important;
}
</style>
